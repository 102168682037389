<template>
	<div class="md-layout">
		<div class="md-layout-item md-small-size-100">
			<ValidationObserver v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(submit)">
					<md-card>
						<md-card-header class="md-card-header-icon md-card-header-green">
							<div class="card-text">
								<h4 class="title">{{ $t("device.editDevice") }}</h4>
							</div>
						</md-card-header>

						<md-card-content>
							<ValidationProvider
								name="name"
								rules="required|regex:^[a-zA-Z0-9-_. ]*$"
								v-slot="{ passed, failed }">
								<md-field
									:class="[
										{ 'md-error': failed },
										{ 'md-valid': passed },
										{ 'md-form-group': true },
									]">
									<md-icon>badge</md-icon>
									<label>{{ $t("fields.device_name.label") }}</label>
									<md-input v-model="device.name" type="text"> </md-input>

									<slide-y-down-transition>
										<md-icon class="error" v-show="failed">close</md-icon>
									</slide-y-down-transition>
									<slide-y-down-transition>
										<md-icon class="success" v-show="passed">done</md-icon>
									</slide-y-down-transition>
									<span class="error-message" v-show="failed">{{
										$t("fields.device_name.validation")
									}}</span>
								</md-field>
							</ValidationProvider>

							<ValidationProvider name="device_groups" v-slot="{ passed, failed }">
								<md-field
									:class="[
										{ 'md-error': failed },
										{ 'md-valid': passed },
										{ 'md-form-group': true },
									]">
									<md-icon>group</md-icon>
									<label>{{ $t("fields.device_group.label") }}</label>
									<md-select
										v-model="device.device_groups"
										name="deviceGroups"
										id="deviceGroups"
										multiple>
										<md-option
											v-for="item in listDeviceGroups"
											:value="item.device_group_id"
											:key="item.device_group_id"
											>{{ item.name }}</md-option
										>
									</md-select>

									<slide-y-down-transition>
										<md-icon class="error" v-show="failed">close</md-icon>
									</slide-y-down-transition>
									<slide-y-down-transition>
										<md-icon class="success" v-show="passed">done</md-icon>
									</slide-y-down-transition>
								</md-field>
							</ValidationProvider>
						</md-card-content>
						<md-card-actions>
							<md-button type="submit" class="md-success">{{ $t("device.updateDevice") }}</md-button>
						</md-card-actions>
					</md-card>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Swal from "sweetalert2";

extend("required", required);

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			device: {
				name: "",
				device_groups: [],
				device_id: 0,
			},
			listDeviceGroups: [],
		};
	},
	async created() {
		let userData = TokenService.getUser();
		if (this.$route.params.device) {
			this.device = this.$route.params.device;
		} else {
			const deviceId = this.$route.params.device_id;

			try {
				const response = await evoapi.get(`/customers/${userData.customerId}/devices/${deviceId}`);
				this.device = response.data;
				// console.log(this.device)
			} catch (error) {
				this.swalBasicErrorDialog();
				// console.error(error)
			}
		}
	},
	mounted() {
		// Get data from API
		evoapi.get("/customers/" + this.device.customer_id + "/device-groups").then((response) => {
			this.listDeviceGroups = response.data;
		});
	},
	methods: {
		validate() {
			return this.$refs.form.validate().then((res) => {
				this.$emit("on-validated", res);
				return res;
			});
		},
		submit() {
			let userData = TokenService.getUser();
			evoapi
				.put("/customers/" + this.device.customer_id + "/devices/" + this.device.device_id, {
					name: this.device.name,
					device_groups: this.device.device_groups,
				})
				.then((response) => {
					this.$router.push({ name: "List Devices" });
				})
				.catch((error) => {
					Swal.fire({
						icon: "error",
						title: this.$t("responses.error.title"),
						text: this.$t("responses.error.content"),
						footer: this.$t("responses.error.footer"),
					});
				});
		},
	},
};
</script>
<style></style>
