<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("sdwan.infoTitle") }}</h4>
					<p v-html="$t('sdwan.detailsInfoBody')"></p>
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("sdwan.list") }}</h4>
					<br />
					<div class="text-right">
						<md-button class="md-info md-round" @click="reloadTableData()">
							<p v-html="$t('device.refresh')"></p>
							<md-icon>refresh</md-icon>
						</md-button>
						<md-button class="md-info md-round" @click="backToSdwanList()">
							<p v-html="$t('sdwan.backToList')"></p>
							<md-icon>arrow_back</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<collapse
						v-for="item in searchedData"
						:key="item.PrivateIpAddress"
						:collapse="[
							'Wireguard tunnel: ' +
								item.GatewayName +
								' (sdwan-bonding-' +
								(item.TunnelNumber + 1) +
								')' +
								' | ' +
								getGatewayStatusIcon(item.GatewayStatus),
						]"
						icon="keyboard_arrow_down"
						color-collapse="warning">
						<template slot="md-collapse-pane-1">
							<strong>Contract ID:</strong> {{ item.ContractID }}<br />
							<strong>Private Tunnel IP Address:</strong> {{ item.PrivateIpAddress }}<br />
							<strong>Gateway:</strong> {{ item.GatewayName }}<br />
							<div v-if="item.GatewayStatus == 'KO'">
								<strong>Gateway Status: </strong>
								<span class="status-ko"></span>
							</div>
							<div v-else-if="item.GatewayStatus == 'OK'">
								<strong>Gateway Status: </strong>
								<span class="status-ok"></span>
							</div>
							<br />
							<div
								v-if="
									item.GatewayLogs &&
									item.GatewayLogs.length > 0 &&
									hasPermission('marketplace:sdwan_status_logs')
								">
								<p style="padding-bottom: 0px !important" v-html="$t('sdwan.stats.logs.title')"></p>
								<table class="log-table">
									<thead>
										<tr>
											<th>Level</th>
											<th>Event</th>
											<th>Threshold</th>
											<th>Count</th>
											<th>Time</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="log in item.GatewayLogs" :key="log.Time">
											<td>{{ log.Level }}</td>
											<td :class="getEventClass(log.TriggerEvent)">{{ log.TriggerEvent }}</td>
											<td>{{ log.Treshold.Valid ? log.Treshold.String : "N/A" }}</td>
											<td>{{ log.Count.Valid ? log.Count.Int64 : "0" }}</td>
											<td>{{ formatDate(log.Time) }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-if="hasPermission('marketplace:sdwan_status_graphs')">
								<p style="padding-bottom: 0px !important" v-html="$t('sdwan.stats.graphs.title')"></p>
								<p
									style="padding-bottom: 0px !important"
									v-html="$t('sdwan.stats.graphs.description')"></p>
								<div class="text-right">
									<md-button
										class="md-info md-round"
										@click="
											goToMonitoringSdwanTrafficPopup(item.DeviceID, item.TunnelNumber + 1, '1h')
										">
										<p
											style="padding-bottom: 0px !important"
											v-html="$t('sdwan.stats.time.last1hour')"></p>
										<md-icon>query_stats</md-icon>
									</md-button>
									<md-button
										class="md-info md-round"
										@click="
											goToMonitoringSdwanTrafficPopup(item.DeviceID, item.TunnelNumber + 1, '6h')
										">
										<p
											style="padding-bottom: 0px !important"
											v-html="$t('sdwan.stats.time.last6hours')"></p>
										<md-icon>query_stats</md-icon>
									</md-button>
									<md-button
										class="md-warning md-round"
										@click="
											goToMonitoringSdwanTrafficPopup(item.DeviceID, item.TunnelNumber + 1, '1d')
										">
										<p
											style="padding-bottom: 0px !important"
											v-html="$t('sdwan.stats.time.last1day')"></p>
										<md-icon>query_stats</md-icon>
									</md-button>
									<md-button
										class="md-primary md-round"
										@click="
											goToMonitoringSdwanTrafficPopup(item.DeviceID, item.TunnelNumber + 1, '1w')
										">
										<p
											style="padding-bottom: 0px !important"
											v-html="$t('sdwan.stats.time.last7days')"></p>
										<md-icon>query_stats</md-icon>
									</md-button>
									<md-button
										class="md-primary md-round"
										@click="
											goToMonitoringSdwanTrafficPopup(item.DeviceID, item.TunnelNumber + 1, '2w')
										">
										<p
											style="padding-bottom: 0px !important"
											v-html="$t('sdwan.stats.time.last2weeks')"></p>
										<md-icon>query_stats</md-icon>
									</md-button>
								</div>
							</div>
							<!-- <button @click="goToMonitoringSdwanTrafficPopup">Vedi il grafico POPUP</button><br> -->
						</template>
					</collapse>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>

<script>
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import { Collapse } from "@/components";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
	name: "PaginatedTable",
	components: {
		Collapse,
	},
	computed: {
		...mapState("monitoringLogin", ["lastLoginTime", "loginRedirectUrl"]),
		...mapGetters("monitoringLogin", ["isLoginValid"]),
		...mapGetters("permissions", ["hasPermission"]),
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}
			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			currentSort: "name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			filters: {
				status: null,
				query: "",
			},
			footerTable: ["Id", "Name", "Serial", "Device Groups", "On Board"],
			propsToSearch: ["name", "serial_number", "device_groups_text"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			deviceGroups: [],
			selectedDevices: [],
			timer: null,
			copiedId: null,
			copiedIp: "",
			intervalId: null,
		};
	},
	methods: {
		...mapActions("monitoringLogin", ["updateLoginTime", "updateLoginRedirectUrl"]),
		getGatewayStatusIcon(status) {
			if (status === "KO") {
				return "[Status KO]";
			} else if (status === "OK") {
				return "[Status: OK]";
			} else {
				return "";
			}
		},
		getEventClass(triggerEvent) {
			if (triggerEvent.startsWith("OK - ")) {
				return "event-ok";
			} else if (triggerEvent.startsWith("KO - ")) {
				return "event-ko";
			} else {
				return "";
			}
		},
		async reloadTableData() {
			let userData = TokenService.getUser();
			let sdwan_contract_id = this.$route.params.sdwan_contract_id;

			evoapi
				.get(`/customers/${userData.customerId}/sdwan/detailedStatus/${sdwan_contract_id}`)
				.then((response) => {
					this.tableData = response.data;
					this.searchedData = this.tableData;
				});
		},
		searchByQuery(results) {
			if (this.filters.query === "") {
				return results;
			}
			this.fuseSearch = new Fuse(results, {
				keys: ["name", "serial_number", "device_groups_text"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});
			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString(undefined, {
				year: "numeric",
				month: "long",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
			});
		},
		toggle(contractId) {
			const item = this.searchedData.find((i) => i.ContractID === contractId);
			if (item) {
				item.showDetails = !item.showDetails;
			}
		},
		backToSdwanList() {
			this.$router.push({ name: "List SDWAN Lite" });
		},
		async goToMonitoringSdwanTrafficPopup(DeviceID, TunnelNumber, range) {
			let userData = TokenService.getUser();

			if (this.isLoginValid) {
				this.showTrafficGraph(DeviceID, TunnelNumber, range, this.loginRedirectUrl);
			} else {
				try {
					const response = await evoapi.post(`/customers/${userData.customerId}/monitoring/login`);
					const loginRedirectUrl = response.data.redirectUrl;
					this.updateLoginTime();
					this.updateLoginRedirectUrl(loginRedirectUrl);
					this.showLoginAndTrafficGraph(DeviceID, TunnelNumber, range, loginRedirectUrl);
				} catch (error) {
					clearInterval(this.intervalId);
					Swal.close();
					Swal.fire({
						title: this.$t("sdwan.stats.error.title"),
						html: this.$t("sdwan.stats.error.content"),
						footer: this.$t("sdwan.stats.error.footer"),
						confirmButtonText: "OK",
						showCloseButton: false,
						icon: "error",
						allowOutsideClick: false,
						backdrop: `rgba(150,0,0,0.4)`,
					});
				}
			}
		},
		async fetchTrafficGraph(DeviceID, TunnelNumber, range, iframe) {
			try {
				let userData = TokenService.getUser();
				const response = await evoapi.get(
					`/customers/${userData.customerId}/monitoring/traffic-graph-id?DeviceID=${DeviceID}&GraphName=Traffic%20on%20sdwan-bonding-${TunnelNumber}`
				);
				const graphid = response.data.result[0].graphid;
				iframe.setAttribute(
					"src",
					`/monitoring/chart2.php?graphid=${graphid}&from=now-${range}&to=now&width=1200&profileIdx=web.charts.filter`
				);
				iframe.style.display = "block";
				Swal.hideLoading();
			} catch (error) {
				Swal.close();
				clearInterval(this.intervalId);
				Swal.close();
				Swal.fire({
					title: this.$t("sdwan.stats.error.title"),
					html: this.$t("sdwan.stats.error.content"),
					footer: this.$t("sdwan.stats.error.footer"),
					confirmButtonText: "OK",
					showCloseButton: false,
					icon: "error",
					allowOutsideClick: false,
					backdrop: `rgba(150,0,0,0.4)`,
				});
			}
		},
		showLoginAndTrafficGraph(DeviceID, TunnelNumber, range, loginRedirectUrl) {
			let userData = TokenService.getUser();
			Swal.fire({
				title: "Monitoring SDWAN Traffic",
				html: `<div style="display: flex; justify-content: center; align-items: center; height: 100%;"><iframe src="" id="loginFrame" style="display:none; width:100%; height:400px; border:none;" scrolling="no"></iframe></div>`,
				showConfirmButton: false,
				width: "70%",
				showCloseButton: true,
				allowOutsideClick: false,
				backdrop: `rgba(0,0,123,0.4)`,
				didOpen: async () => {
					Swal.showLoading();
					const iframe = document.getElementById("loginFrame");
					iframe.onload = async () => {
						if (iframe.getAttribute("src") === loginRedirectUrl) {
							const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
							const style = document.createElement("style");
							style.textContent = `
						body, html {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100%;
						margin: 0;
						}
						img {
						max-width: 100%;
						max-height: 100%;
						}
					`;
							iframeDoc.head.appendChild(style);

							// Dopo che l'iframe ha caricato il loginRedirectUrl, carica il grafico
							await new Promise((resolve) => setTimeout(resolve, 2000)); // Aspetta 2 secondi per assicurarsi che il login sia completato
							this.fetchTrafficGraph(DeviceID, TunnelNumber, range, iframe);
							this.intervalId = setInterval(() => {
								this.fetchTrafficGraph(DeviceID, TunnelNumber, range, iframe);
							}, 10000); // 10 secondi
						}
					};
					iframe.setAttribute("src", loginRedirectUrl);
				},
				willClose: () => {
					clearInterval(this.intervalId); // Cancella l'intervallo quando il popup viene chiuso
				},
			});
		},
		showTrafficGraph(DeviceID, TunnelNumber, range, loginRedirectUrl) {
			let userData = TokenService.getUser();
			Swal.fire({
				title: "Monitoring SDWAN Traffic",
				html: `<div style="display: flex; justify-content: center; align-items: center; height: 100%;"><iframe src="" id="loginFrame" style="display:none; width:100%; height:400px; border:none;" scrolling="no"></iframe></div>`,
				showConfirmButton: false,
				width: "70%",
				showCloseButton: true,
				allowOutsideClick: false,
				backdrop: `rgba(0,0,123,0.4)`,
				didOpen: async () => {
					Swal.showLoading();
					const iframe = document.getElementById("loginFrame");
					this.fetchTrafficGraph(DeviceID, TunnelNumber, range, iframe);
					this.intervalId = setInterval(() => {
						this.fetchTrafficGraph(DeviceID, TunnelNumber, range, iframe);
					}, 15000); // 15 secondi

					Swal.getConfirmButton().addEventListener("click", () => {
						clearInterval(this.intervalId); // Cancella l'intervallo quando il popup viene chiuso
					});
				},
				willClose: () => {
					clearInterval(this.intervalId); // Cancella l'intervallo quando il popup viene chiuso
				},
			});
		},
	},
	mounted() {
		this.reloadTableData();
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;
				results = this.searchByQuery(results);
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>

<style scoped>
.log-table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
}

.log-table th,
.log-table td {
	border: 1px solid #ccc;
	padding: 8px;
	text-align: left;
}

.log-table th {
	background-color: #f4f4f4;
}

.md-list-item {
	cursor: pointer;
}
.md-collapse {
	margin-left: 20px;
}
.status-ok {
	height: 10px;
	width: 10px;
	background-color: green;
	border-radius: 50%;
	display: inline-block;
}
.status-ko {
	height: 10px;
	width: 10px;
	background-color: red;
	border-radius: 50%;
	display: inline-block;
}
.event-ok {
	color: green;
	font-weight: bold;
}

.event-ko {
	color: red;
	font-weight: bold;
}
</style>
