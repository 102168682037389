<template>
	<div class="wrapper" :class="[{ 'nav-open': $sidebar.showSidebar }, { rtl: $route.meta.rtlActive }]">
		<notifications></notifications>
		<side-bar
			:active-color="sidebarBackground"
			:background-image="sidebarBackgroundImage"
			:data-background-color="sidebarBackgroundColor">
			<!-- <user-mene-menu></mobile-menu> -->
			<template slot="links" v-if="loggedIn">
				<sidebar-item
					v-if="hasPermission('dashboard:dashboard_list')"
					:link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }">
				</sidebar-item>

				<sidebar-item
					:link="{
						name: $t('sidebar.administration'),
						icon: 'admin_panel_settings',
						disableCollapse: true,
					}">
					<sidebar-item
						v-if="hasPermission('admin:tenant_list')"
						:link="{
							name: $t('sidebar.tenants'),
							icon: 'business',
							path: '/tenants',
						}"></sidebar-item>
					<sidebar-item
						v-if="hasPermission('admin:customer_list')"
						:link="{
							name: $t('sidebar.customers'),
							icon: 'perm_contact_calendar',
							path: '/customers',
						}"></sidebar-item>
					<sidebar-item
						v-if="hasPermission('admin:user_list')"
						:link="{
							name: $t('sidebar.users'),
							icon: 'manage_accounts',
							path: '/users',
						}"></sidebar-item>
				</sidebar-item>
				<sidebar-item
					:link="{
						name: $t('sidebar.devices_menu'),
						icon: 'menu_open',
						disableCollapse: true,
					}">
					<sidebar-item
						v-if="hasPermission('device:device_list')"
						:link="{
							name: $t('sidebar.devices'),
							icon: 'router',
							path: '/devices',
						}" />
					<sidebar-item
						v-if="hasPermission('device:device_group_list')"
						:link="{
							name: $t('sidebar.device_groups'),
							icon: 'router',
							path: '/device-groups',
						}" />
				</sidebar-item>
				<sidebar-item
					:link="{
						name: $t('sidebar.provision_menu'),
						icon: 'menu_open',
						disableCollapse: true,
					}">
					<sidebar-item
						v-if="hasPermission('provision:template_list')"
						:link="{
							name: $t('sidebar.templates'),
							icon: 'text_snippet',
							path: '/templates',
						}" />
					<sidebar-item
						v-if="hasPermission('provision:provision_list')"
						:link="{
							name: $t('sidebar.provisioners'),
							icon: 'location_searching',
							path: '/provisioners',
						}" />
				</sidebar-item>
				<sidebar-item
					v-if="hasPermission('monitoring:monitoring_list')"
					:link="{
						name: $t('sidebar.monitoring'),
						icon: 'monitor_heart',
						path: '/monitoring',
						isRoute: false,
					}"></sidebar-item>
				<sidebar-item
					v-if="hasPermission('backup:backup_list')"
					:link="{
						name: $t('sidebar.backups'),
						icon: 'cloud_done',
						path: '/backups',
					}"></sidebar-item>
				<sidebar-item
					v-if="hasPermission('marketplace:marketplace_list')"
					:link="{
						name: $t('sidebar.marketplace'),
						icon: 'store',
						disableCollapse: true,
					}"
					class="space">
					<!-- <div v-if="shieldAvailable"> -->
					<sidebar-item
						v-if="hasPermission('marketplace:sdwan_list')"
						:link="{ name: $t('sidebar.sdwan-shield'), icon: 'merge', path: '/sdwan' }" />
					<!-- </div>
					<div v-else>
					<sidebar-item
						:link="{ name: $t('sidebar.sd_wan'), icon: 'lan', path: '/sdwan' }"
					/>
					</div> -->
					<sidebar-item
						v-if="hasPermission('marketplace:sdwan_lite_list')"
						:link="{ name: $t('sidebar.sdwan-lite'), icon: 'alt_route', path: '/sdwan-lite' }" />
					<sidebar-item
						v-if="hasPermission('marketplace:elastic_ip_list')"
						:link="{ name: $t('sidebar.elastic-ip'), icon: 'cloud', path: '/elastic-ips' }" />
					<sidebar-item
						v-if="hasPermission('marketplace:content_filtering_list')"
						:link="{
							name: $t('sidebar.content-filtering'),
							icon: 'security',
							path: '/content-filtering',
						}" />
				</sidebar-item>
				<sidebar-item
					v-if="hasPermission('usage:usage_list')"
					:link="{
						name: $t('sidebar.usage'),
						icon: 'leaderboard',
						path: '/stats',
					}">
					<sidebar-item
						v-if="hasPermission('usage:stats_list')"
						:link="{
							name: $t('sidebar.stats'),
							icon: 'analytics',
							path: '/stats',
						}" />
					<sidebar-item
						v-if="hasPermission('usage:licenses_list')"
						:link="{
							name: $t('sidebar.licenses'),
							icon: 'workspace_premium',
							path: '/licenses',
						}" />
				</sidebar-item>
				<sidebar-item
					v-if="hasPermission('profile:profile_list')"
					:link="{
						name: $t('sidebar.profile'),
						icon: 'person',
						path: '/profile',
					}"></sidebar-item>
				<sidebar-item
					:link="{
						name: $t('sidebar.logout'),
						icon: 'logout',
						path: '/logout',
					}"></sidebar-item>
			</template>
		</side-bar>
		<div class="main-panel">
			<top-navbar></top-navbar>
			<div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
				<zoom-center-transition :duration="200" mode="out-in">
					<!-- your content here -->
					<router-view></router-view>
				</zoom-center-transition>
			</div>
			<content-footer v-if="!$route.meta.hideFooter"></content-footer>
		</div>
	</div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
// import MobileMenu from "./Extra/MobileMenu.vue";
// import FixedPlugin from "../../FixedPlugin.vue";
// import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import TokenService from "@/services/token";
import { mapGetters } from "vuex";
import SidebarItem from "../../../components/SidebarPlugin/SidebarItem.vue";

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
		document.getElementsByClassName(className)[0].scrollTop = 0;
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

function reinitScrollbar() {
	let docClasses = document.body.classList;
	let isWindows = navigator.platform.startsWith("Win");
	if (isWindows) {
		// if we are on windows OS we activate the perfectScrollbar function
		initScrollbar("sidebar");
		initScrollbar("sidebar-wrapper");
		initScrollbar("main-panel");

		docClasses.add("perfect-scrollbar-on");
	} else {
		docClasses.add("perfect-scrollbar-off");
	}
}

export default {
	components: {
		TopNavbar,
		ContentFooter,
		// FixedPlugin,
		// MobileMenu,
		// UserMenu,
		ZoomCenterTransition,
		SidebarItem,
	},
	data() {
		return {
			shieldAvailable: false,
			sidebarBackgroundColor: "black",
			sidebarBackground: "purple",
			sidebarBackgroundImage: "./img/sidebar-2.jpg",
			sidebarMini: true,
			sidebarImg: true,
			isAdmin: false,
			role: "",
		};
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		},
		minimizeSidebar() {
			if (this.$sidebar) {
				this.$sidebar.toggleMinimize();
			}
		},
	},
	updated() {
		reinitScrollbar();
	},
	mounted() {
		reinitScrollbar();
		const userData = TokenService.getUser();
		this.isAdmin = userData.isAdmin;
		this.role = userData.role;
	},
	watch: {
		sidebarMini() {
			this.minimizeSidebar();
		},
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		loggedIn() {
			let auth = this.$store.state.auth;
			return auth.status.loggedIn;
		},
	},
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
	from {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
	to {
		opacity: 1;
	}
}
.main-panel .zoomIn {
	animation-name: zoomIn95;
}
@keyframes zoomOut95 {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
}
.main-panel .zoomOut {
	animation-name: zoomOut95;
}
</style>
