<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard>
				<template slot="header">
					<h5 class="category">{{ $t("sdwan.wizard.title") }}</h5>
				</template>

				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label">{{ $t("sdwan.wizard.title_step1") }}</template>
					<first-step
						ref="step1"
						:addresses="addresses"
						:sdwan-regions="sdwanRegions"
						:sdwan-concentrators="sdwanConcentrators"
						:lan-interfaces="lanInterfaces"
						:sdwan-connection="sdwanConnection"
						@on-validated="onStepValidated"></first-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step2')">
					<template slot="label">{{ $t("sdwan.wizard.title_step2") }}</template>
					<second-step
						ref="step2"
						:addresses="addresses"
						:gateways-props="gateways"
						:shield-available="shieldAvailable"
						:gateway-shield-active="gatewayShieldActive"
						:sdwan-type="currentSdwanType"
						@on-validated="wizardComplete"
						@on-gateway-shield="toggleGatewayShield"></second-step>
				</wizard-tab>
			</simple-wizard>
		</div>
	</div>
</template>
<script>
import FirstStep from "./WizardSteps/FirstProvisionedStep.vue";
import SecondStep from "./WizardSteps/SecondProvisionedStep.vue";
import Swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import { swalUtils } from "@/mixins/mixin";
import { extend } from "vee-validate";

const subnetRegex =
	/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/([0-9]|[1-2][0-9]|3[0-2])$/;

extend("subnet", {
	validate: (value) => subnetRegex.test(value),
	message: "This field must be a valid IPv4 subnet",
});

export default {
	mixins: [swalUtils],
	data() {
		return {
			wizardModel: {},
			editedSDWAN: false,
			addresses: [],
			gateways: [],
			publicIP: "",
			shieldAvailable: false,
			gatewayShield: false,
			gatewayShieldActive: false,
			sdwanRegions: [],
			sdwanConcentrators: [],
			lanInterfaces: [],
			selectedLANInterfaces: [],
			currentSdwanType: this.$route.params.sdwanType || "1",
		};
	},
	props: ["device_id", "sdwanConnection"],
	components: {
		FirstStep,
		SecondStep,
		SimpleWizard,
		WizardTab,
	},
	mounted() {
		// logging sdwanConnection
		// console.log("sdwanConnection: ", this.sdwanConnection);
		let userData = TokenService.getUser();
		if (this.sdwanConnection) {
			this.$refs.step1.cpe = this.sdwanConnection.cpe;
			this.$refs.step1.sdwanType = this.sdwanConnection.sdwanType;
			this.$refs.step1.lan_network = this.sdwanConnection.lan_network;
			// this.$refs.step1.sdwan_cidr_addr = this.sdwanConnection.sdwan_cidr_addr;
			this.$refs.step1.contract_id = this.sdwanConnection.contract_id;
			this.$refs.step1.description = this.sdwanConnection.description;
			this.$refs.step1.concentrator_id = this.sdwanConnection.concentrator_id;
			this.$refs.step1.region_id = this.sdwanConnection.region_id;
			this.$refs.step1.elastic_ip = this.sdwanConnection.sdwan_cidr;
			this.$refs.step2.gateways = this.sdwanConnection.gateways;
			this.$refs.step2.concentrator_id = this.sdwanConnection.concentrator_id;
			this.gatewayShieldActive = this.sdwanConnection.shield_active ?? false;
			this.$refs.step2.gatewayShield = false;
			this.editedSDWAN = true;
			this.$refs.step1.sdwanTypeDisabled = true;

			// if (this.sdwanConnection.sdwanType === "1") {
			// 	const allSameLocalPreference = this.sdwanConnection.gateways.every((val, i, arr) => {
			// 		return val.options.local_preference === arr[0].options.local_preference;
			// 	});
			// 	this.$refs.step2.aggregationMode = allSameLocalPreference;
			// }
		}
		this.$refs.step1.uniqueNetworks = this.uniqueNetworks;
		this.loadSDWANRegions(this.editedSDWAN); // Carica le regioni SDWAN
		this.loadSDWANConcentrators(this.editedSDWAN); // Carica i concentratori SDWAN

		evoapi
			.get(`/customers/${userData.customerId}/uania/canActivate`)
			.then(() => (this.shieldAvailable = true))
			.catch(() => (this.shieldAvailable = false));

		var localThis = this;
		evoapi
			.get(`/customers/${userData.customerId}/sdwan/provisionedData/${this.device_id}`)
			.then((response) => localThis.handleSDWANData(response.data))
			.catch(() => this.swalBasicErrorDialog().then(() => localThis.redirectToDeviceList()));
	},
	methods: {
		getUniqueNetworks() {
			const networks = this.addresses.map((address) => address.network + "/24");
			this.uniqueNetworks = [...new Set(networks)]; // rimuove i duplicati
		},
		handleSDWANData(data) {
			this.addresses = data.ips;
			var gateways = [];
			data.routes.forEach(function (route) {
				gateways.push(route.gateway);
			});
			data.interfaces.forEach(function (interf) {
				gateways.push(interf.name);
			});

			// cycle in gateways and remove duplicates
			gateways = [...new Set(gateways)];
			// reorder gateway by alphanumeric order
			gateways.sort((a, b) => a.localeCompare(b));

			this.gateways = gateways;
			this.publicIP = data.public_ip;

			// Estrai le interfacce LAN
			this.lanInterfaces = data.interface_lists
				.filter((item) => item.list === "LAN")
				.map((item) => item.interface);
		},

		redirectToDeviceList() {
			if (!this.editedSDWAN) {
				this.$router.push({ name: "List Devices" });
			} else {
				this.$router.push({ name: "List SDWAN Lite" });
			}
		},
		validateStep(ref) {
			// setting checkbox aggregationMode in second step true if sdwanType is 1 in firststep
			if (ref == "step1") {
				if (this.$refs.step1.sdwanType == 1) {
					// // logging
					// console.log("sdwanType is 1, enabling aggregationMode checkbox in second step");
					this.$refs.step2.aggregationMode = false;
				}
				if (this.sdwanConnection && this.sdwanConnection.sdwanType === "1") {
					// const allSameLocalPreference = this.sdwanConnection.gateways.every((val, i, arr) => {
					// 	return val.options.local_preference === arr[0].options.local_preference;
					// });
					// if(this.$refs.step2) {
					// 	this.$refs.step2.aggregationMode = allSameLocalPreference;
					// }
				}
			}
			return this.$refs[ref].validate();
		},
		onStepValidated(validated, model) {
			if (model) {
				this.wizardModel = { ...this.wizardModel, ...model };
				if (model.selectedInterfaces) {
					this.selectedLANInterfaces = model.selectedInterfaces;
				}
				this.currentSdwanType = model.sdwanType;
				if (this.$refs.step2) {
					this.$refs.step2.sdwanType = model.sdwanType;
				}
			}
		},
		toggleGatewayShield(shieldActive) {
			this.gatewayShield = shieldActive;
		},
		async wizardComplete() {
			let userData = TokenService.getUser();
			//console.log("wizardComplete");
			// creating concentrator_id from concentratorID and regionID saved in selctedConcentrator in format concentratorID_regionID
			this.$refs.step1.concentrator_id = this.$refs.step1.selectedConcentrator.split("_")[0];
			// transforming in integer
			this.$refs.step1.concentrator_id = parseInt(this.$refs.step1.concentrator_id);

			let req = {
				contract_id: parseInt(this.$refs.step1.contract_id),
				description: this.$refs.step1.description,
				sdwanType: this.$refs.step1.sdwanType,
				lan_network: this.$refs.step1.lan_network,
				cpe: {
					host: this.publicIP,
				},
				gateways: this.$refs.step2.gateways,
				device_id: this.device_id,
				shield_active: this.gatewayShield,
				aggregationMode: this.$refs.step2.aggregationMode,
				concentrator_id: this.$refs.step1.concentrator_id,
				sdwan_cidr: this.$refs.step1.selectedElasticIp,
				lan_interfaces: this.$refs.step1.selectedInterfaces, // Aggiungiamo le interfacce LAN selezionate
				sdwan_cidr_addr: this.$refs.step1.sdwan_cidr_addr,
			};

			// if (req.aggregationMode === false && req.sdwanType === '1') {
			// 	let preference = 110;
			// 	req.gateways = req.gateways.map(gateway => {
			// 		gateway.options.local_preference = preference;
			// 		preference--;
			// 		return gateway;
			// 	});
			// }
			// if (req.aggregationMode === true && req.sdwanType === '1') {
			// 	let preference = 110;
			// 	req.gateways = req.gateways.map(gateway => {
			// 		gateway.options.local_preference = preference;
			// 		return gateway;
			// 	});
			// }
			// ora devo trasformare in integer i valori di local_preference perchè potrebbero essere stringhe
			req.gateways = req.gateways.map((gateway) => {
				gateway.options.local_preference = parseInt(gateway.options.local_preference);
				return gateway;
			});

			// logging req and stopping
			// console.log("req : " + JSON.stringify(req));
			// return;

			Swal.fire({
				title: this.$t("dialogs.configuring_sdwan.title"),
				html: this.$t("dialogs.configuring_sdwan.content"),
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
					// checking device availability
					// logging device_id
					evoapi
						.get(
							"/customers/" + userData.customerId + "/health/check-device-availability/" + this.device_id
						)
						.then((response) => {
							Swal.close(); // Chiudi la notifica di attesa
							if (response.status === 200) {
								// Editing case
								if (this.editedSDWAN) {
									(req.contract_id = parseInt(this.$refs.step1.contract_id)),
										evoapi
											.put(
												"/customers/" +
													userData.customerId +
													"/sdwan/config/" +
													req.contract_id,
												req
											)
											.then((response) => {
												const shieldError =
													(this.gatewayShield && response["data"]["shieldError"]) ?? false;
												Swal.close();
												if (response.status === 200) {
													Swal.fire({
														title: this.$t("dialogs.update_sdwan_completed.title"),
														text: this.$t("dialogs.update_sdwan_completed.content"),
														buttonsStyling: false,
														confirmButtonClass: "md-button md-success",
														icon: "success",
														footer: shieldError ? this.$t("responses.error.footer") : "",
													});
													this.$router.push({ name: "List SDWAN Lite" });
												} else {
													throw new Error("Device not available");
												}
											})
											.catch((error) => {
												if (
													error.response.data.error ===
													"Local preference must be between 107 and 110"
												) {
													Swal.fire({
														title: this.$t("dialogs.update_sdwan_failed.title"),
														html: this.$t("dialogs.update_sdwan_failed.content_local_pref"),
														confirmButtonText: this.$t("dialogs.update_sdwan_failed.ok"),
														showCloseButton: false,
														icon: "error",
														allowOutsideClick: false,
														backdrop: `rgba(150,0,0,0.4)`,
													}).then(() => {
														// this.fetchData();
													});
												} else {
													Swal.fire({
														title: this.$t("dialogs.update_sdwan_failed.title"),
														html: this.$t("dialogs.update_sdwan_failed.content"),
														confirmButtonText: this.$t("dialogs.update_sdwan_failed.ok"),
														showCloseButton: false,
														icon: "error",
														allowOutsideClick: false,
														backdrop: `rgba(150,0,0,0.4)`,
													}).then(() => {
														// this.fetchData();
													});
												}
											});
								} else {
									// Adding case
									evoapi
										.post("/customers/" + userData.customerId + "/sdwan/config", req)
										.then((response) => {
											Swal.close();
											Swal.fire({
												title: this.$t("dialogs.create_sdwan_completed.title"),
												text: this.$t("dialogs.create_sdwan_completed.content"),
												buttonsStyling: false,
												confirmButtonClass: "md-button md-success",
												icon: "success",
											});
											this.$router.push({ name: "List SDWAN Lite" });
										})
										.catch((error) => {
											if (error.response) {
												if (error.response.status === 403) {
													Swal.fire({
														icon: "error",
														title: this.$t("responses.error.title"),
														html:
															this.$t("licence.activate.error.content") +
															"<code>404</code>",
														footer: this.$t("responses.error.footer"),
														showCloseButton: false,
														icon: "error",
														allowOutsideClick: false,
														backdrop: `rgba(150,0,0,0.4)`,
													});
												} else if (
													error.response.data.error ===
													"Local preference must be between 107 and 110"
												) {
													Swal.fire({
														title: this.$t("dialogs.update_sdwan_failed.title"),
														html: this.$t("dialogs.update_sdwan_failed.content_local_pref"),
														confirmButtonText: this.$t("dialogs.update_sdwan_failed.ok"),
														showCloseButton: false,
														icon: "error",
														allowOutsideClick: false,
														backdrop: `rgba(150,0,0,0.4)`,
													});
												} else {
													Swal.fire({
														icon: "error",
														title: this.$t("dialogs.sdwan_error.title"),
														html:
															this.$t("dialogs.sdwan_error.content") +
															"<br><br><code>" +
															error.response.data.error +
															"</code>",
														footer: this.$t("dialogs.sdwan_error.footer"),
													});
												}
											}
										});
								}
							} else {
								throw new Error("Device not available");
							}
						})
						.catch((error) => {
							Swal.fire({
								title: this.$t("dialogs.check_device_availability.title"),
								html: this.$t("dialogs.check_device_availability.content"),
								confirmButtonText: this.$t("dialogs.check_device_availability.ok"),
								showCloseButton: false,
								icon: "error",
								allowOutsideClick: false,
								backdrop: `rgba(150,0,0,0.4)`,
							});
						});
				},
			});
		},
		loadSDWANRegions(edited) {
			let userData = TokenService.getUser();
			evoapi
				.get(`/customers/${userData.customerId}/elasticIp/listRegions`)
				.then((response) => {
					// Accesso alla proprietà 'regions' dell'oggetto response.data
					let regions = response.data.regions;

					if (edited) {
						// Filtra le regioni in base al region_id
						this.sdwanRegions = regions.filter(
							(region) => region.region_id == this.sdwanConnection.region_id
						);
						// console.log("sdwanRegions: ", this.sdwanRegions);
					} else {
						this.sdwanRegions = regions;
					}
				})
				.catch((error) => {
					// console.error("Errore nel caricamento delle regioni SDWAN", error);
				});
		},
		loadSDWANConcentrators(edited) {
			let userData = TokenService.getUser();
			evoapi
				.get(`/customers/${userData.customerId}/sdwan/concentrators`)
				.then((response) => {
					let concentrators = response.data;
					// Se 'edited' è true, filtra i concentratori
					if (edited && this.sdwanConnection && this.sdwanConnection.concentrator_id) {
						concentrators = concentrators.filter(
							(concentrator) => concentrator.ID == this.sdwanConnection.concentrator_id
						);
					}

					// Aggiungi la proprietà concentratorID_regionID a ogni concentratore
					concentrators.forEach((concentrator) => {
						concentrator.concentratorID_regionID = concentrator.ID + "_" + concentrator.RegionID;
					});

					this.sdwanConcentrators = concentrators;
				})
				.catch((error) => {
					// console.error("Errore nel caricamento dei concentratori SDWAN", error);
				});
		},
	},
};
</script>
