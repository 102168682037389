import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Pages
// import User from "@/pages/Dashboard/Pages/UserProfile.vue";
// import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
// import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
// import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import ForgotPassword from "@/pages/Dashboard/Pages/ForgotPassword.vue";
// import Register from "@/pages/Dashboard/Pages/Register.vue";
// import Lock from "@/pages/Dashboard/Pages/Lock.vue";
// Components pages
// import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
// import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
// import Panels from "@/pages/Dashboard/Components/Panels.vue";
// import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
// import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
// import Icons from "@/pages/Dashboard/Components/Icons.vue";
// import Typography from "@/pages/Dashboard/Components/Typography.vue";
// Forms pages
// import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
// import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
// import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
// import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";
// TableList pages
// import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
// import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
// import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";
// Maps pages
// import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
// import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
// import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";
// // Calendar
// import Calendar from "@/pages/Dashboard/Calendar.vue";
// // Charts
// import Charts from "@/pages/Dashboard/Charts.vue";
// import Widgets from "@/pages/Dashboard/Widgets.vue";
// Cloudtik pages
import ListProvisioners from "@/pages/Dashboard/Provisioners/ListProvisioners.vue";
import ListNeigbourDevices from "@/pages/Dashboard/Provisioners/ListNeigbourDevices.vue";
import ProvisionDeviceWizard from "@/pages/Dashboard/Provisioners/Wizard.vue";
import ListDevices from "@/pages/Dashboard/Devices/ListDevices.vue";
import AddDeviceWizard from "@/pages/Dashboard/Devices/Wizard.vue";
import ListTemplates from "@/pages/Dashboard/Templates/ListTemplates.vue";
import NewTemplate from "@/pages/Dashboard/Templates/NewTemplate.vue";
import EditTemplateForm from "@/pages/Dashboard/Templates/EditTemplateForm.vue";
import AddProvisionerWizard from "@/pages/Dashboard/Provisioners/WizardNew.vue";
import ListSDWAN from "@/pages/Dashboard/SDWAN/ListSDWAN.vue";
import ListSDWANLite from "@/pages/Dashboard/SDWAN/ListSDWANLite.vue";
import AddSDWANWizard from "@/pages/Dashboard/SDWAN/Wizard.vue";
import AddSDWANWizardLite from "@/pages/Dashboard/SDWAN/WizardLite.vue";
import AddSDWANWizardProvisioned from "@/pages/Dashboard/SDWAN/WizardProvisioned.vue";
import AddSDWANWizardProvisionedLite from "@/pages/Dashboard/SDWAN/WizardProvisionedLite.vue";
import SdwanDetailsContent from "@/pages/Dashboard/SDWAN/SdwanDetailsContent.vue";
import SdwanDetailsContentLite from "@/pages/Dashboard/SDWAN/SdwanDetailsContentLite.vue";
import ListNotifications from "@/pages/Dashboard/Notifications/ListNotifications.vue";
import MonitoringFrame from "@/pages/Dashboard/Monitoring/MonitoringFrame.vue";
import ListDeviceGroups from "@/pages/Dashboard/DeviceGroups/ListDeviceGroups.vue";
import NewDeviceGroup from "@/pages/Dashboard/DeviceGroups/NewDeviceGroup.vue";
import EditDeviceGroupForm from "@/pages/Dashboard/DeviceGroups/EditDeviceGroupForm.vue";
import EditDevice from "@/pages/Dashboard/Devices/EditDevice.vue";
import ProvisionDevices from "@/pages/Dashboard/Devices/ProvisionDevices.vue";
import ListDevicesWithBackup from "@/pages/Dashboard/Backups/ListDevicesWithBackup.vue";
import ListDeviceBackups from "@/pages/Dashboard/Backups/ListDeviceBackups.vue";
import Troubleshooting from "@/pages/Dashboard/Troubleshooting/Troubleshooting.vue";
import Logout from "@/pages/Dashboard/Logout/Logout.vue";
import EditProfileForm from "@/pages/Dashboard/Profile/EditProfileForm.vue";
import ListCustomer from "@/pages/Dashboard/Customer/ListCustomer.vue";
import ListUser from "@/pages/Dashboard/User/ListUser.vue";
import ListTenant from "@/pages/Dashboard/Tenant/ListTenant.vue";
import TokenService from "@/services/token";
// import WrapperIframe from "@/pages/Dashboard/Uania/WrapperIframe.vue";
import NewCustomerForm from "@/pages/Dashboard/Customer/NewCustomerForm.vue";
import NewTenantForm from "@/pages/Dashboard/Tenant/NewTenantForm.vue";
import NewUserForm from "@/pages/Dashboard/User/NewUserForm.vue";
import ListContentFiltering from "@/pages/Dashboard/ContentFiltering/ListContentFiltering.vue";
import NewContentFiltering from "@/pages/Dashboard/ContentFiltering/NewContentFiltering.vue";
import WrapperIframeFS from "@/pages/Dashboard/ContentFiltering/WrapperIframeFS.vue";
import ListElasticIps from "@/pages/Dashboard/ElasticIps/ListElasticIps.vue";
import NewElasticIp from "@/pages/Dashboard/ElasticIps/NewElasticIp.vue";
import Stats from "@/pages/Dashboard/Usage/Stats.vue";
import Licenses from "@/pages/Dashboard/Usage/Licenses.vue";
import LicenseForm from "@/pages/Dashboard/Usage/LicenseForm.vue";
// let componentsMenu = {
//   path: "/components",
//   component: DashboardLayout,
//   redirect: "/components/buttons",
//   name: "Components",
//   children: [
//     {
//       path: "buttons",
//       name: "Buttons",
//       components: { default: Buttons },
//     },
//     {
//       path: "grid-system",
//       name: "Grid System",
//       components: { default: GridSystem },
//     },
//     {
//       path: "panels",
//       name: "Panels",
//       components: { default: Panels },
//     },
//     {
//       path: "sweet-alert",
//       name: "Sweet Alert",
//       components: { default: SweetAlert },
//     },
//     {
//       path: "notifications",
//       name: "Notifications",
//       components: { default: Notifications },
//     },
//     {
//       path: "icons",
//       name: "Icons",
//       components: { default: Icons },
//     },
//     {
//       path: "typography",
//       name: "Typography",
//       components: { default: Typography },
//     },
//   ],
// };
// let formsMenu = {
//   path: "/forms",
//   component: DashboardLayout,
//   redirect: "/forms/regular",
//   name: "Forms",
//   children: [
//     {
//       path: "regular",
//       name: "Regular Forms",
//       components: { default: RegularForms },
//     },
//     {
//       path: "extended",
//       name: "Extended Forms",
//       components: { default: ExtendedForms },
//     },
//     {
//       path: "validation",
//       name: "Validation Forms",
//       components: { default: ValidationForms },
//     },
//     {
//       path: "wizard",
//       name: "Wizard",
//       components: { default: Wizard },
//     },
//   ],
// };

// let tablesMenu = {
//   path: "/table-list",
//   component: DashboardLayout,
//   redirect: "/table-list/regular",
//   name: "Tables",
//   children: [
//     {
//       path: "regular",
//       name: "Regular Tables",
//       components: { default: RegularTables },
//     },
//     {
//       path: "extended",
//       name: "Extended Tables",
//       components: { default: ExtendedTables },
//     },
//     {
//       path: "paginated",
//       name: "Pagianted Tables",
//       components: { default: PaginatedTables },
//     },
//   ],
// };

// let mapsMenu = {
//   path: "/maps",
//   component: DashboardLayout,
//   name: "Maps",
//   redirect: "/maps/google",
//   children: [
//     {
//       path: "google",
//       name: "Google Maps",
//       components: { default: GoogleMaps },
//     },
//     {
//       path: "full-screen",
//       name: "Full Screen Map",
//       meta: {
//         hideContent: true,
//         hideFooter: true,
//         navbarAbsolute: true,
//       },
//       components: { default: FullScreenMap },
//     },
//     {
//       path: "vector-map",
//       name: "Vector Map",
//       components: { default: VectorMaps },
//     },
//   ],
// };

// let pagesMenu = {
//   path: "/pages",
//   component: DashboardLayout,
//   name: "Pages",
//   redirect: "/pages/user",
//   children: [
//     {
//       path: "user",
//       name: "User Page",
//       components: { default: User },
//     },
//     {
//       path: "timeline",
//       name: "Timeline Page",
//       components: { default: TimeLine },
//     },
//     {
//       path: "rtl",
//       name: "وحة القيادة",
//       meta: {
//         rtlActive: true,
//       },
//       components: { default: RtlSupport },
//     },
//   ],
// };

let authPages = {
	path: "/",
	component: AuthLayout,
	name: "Authentication",
	children: [
		{
			path: "/login",
			name: "Login",
			component: Login,
		},
		{
			path: "/forgot-password",
			name: "ForgotPassword",
			component: ForgotPassword,
		},
		// {
		//   path: "/register",
		//   name: "Register",
		//   component: Register,
		// },
		// {
		//   path: "/pricing",
		//   name: "Pricing",
		//   component: Pricing,
		// },
		// {
		//   path: "/lock",
		//   name: "Lock",
		//   component: Lock,
		// },
	],
};

let cloudtikProvisioners = {
	path: "/provisioners",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List Provisioners",
			component: ListProvisioners,
		},
		{
			path: "/:provisioner_id/devices",
			name: "List Neighbour Devices",
			component: ListNeigbourDevices,
			props: true,
		},
		{
			path: "/:provisioner_id/add-device/:mac_address",
			name: "Add Neighbour Device",
			component: ProvisionDeviceWizard,
			props: true,
		},
		,
		{
			path: "/add",
			name: "Add Provisioner",
			component: AddProvisionerWizard,
			props: true,
		},
	],
};

let cloudtikDevices = {
	path: "/devices",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List Devices",
			component: ListDevices,
		},
		{
			path: "/add",
			name: "Add Device",
			component: AddDeviceWizard,
		},
		{
			path: "/edit/:device_id",
			name: "Edit device",
			component: EditDevice,
			props: true,
		},
		{
			path: "/provision",
			name: "Provision devices",
			component: ProvisionDevices,
			props: true,
		},
	],
};

let cloudtikTemplates = {
	path: "/templates",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List Templates",
			component: ListTemplates,
		},
		{
			path: "/templates/add",
			name: "Add template",
			component: NewTemplate,
			props: true,
		},
		{
			path: "/templates/edit",
			name: "Edit template",
			component: EditTemplateForm,
			props: true,
		},
	],
};

let cloudtikSDWAN = {
	path: "/sdwan",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List SDWAN",
			component: ListSDWAN,
		},
		// {
		//   path: "/sdwan/add",
		//   name: "Add SDWAN",
		//   component: AddSDWANWizard,
		//   props: true,
		// },
		{
			path: "/sdwan/edit",
			name: "Edit SDWAN",
			component: AddSDWANWizard,
			props: true,
		},
		{
			path: "/sdwan/add-provisioned/:device_id",
			name: "Start SDWAN",
			component: AddSDWANWizardProvisioned,
			props: true,
		},
		{
			path: "/sdwan/edit-provisioned/:device_id",
			name: "Edit Provisioned SDWAN",
			component: AddSDWANWizardProvisioned,
			props: true,
		},
		{
			path: "/sdwan/detailed-status/:sdwan_contract_id",
			name: "SDWAN Details",
			component: SdwanDetailsContent,
			props: true,
		},
		// {
		// 	path: "/sdwan/shield/:customerID",
		// 	name: "Uania Web Panel",
		// 	component: WrapperIframe,
		// 	props: true,
		// },
		{
			path: "/github",
			beforeEnter() {
				location.href = process.env.VUE_APP_BACKOFFICE_URL;
			},
		},
	],
};
let cloudtikSDWANLite = {
	path: "/sdwan-lite",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List SDWAN Lite",
			component: ListSDWANLite,
		},
		// {
		//   path: "/sdwan/add",
		//   name: "Add SDWAN",
		//   component: AddSDWANWizard,
		//   props: true,
		// },
		{
			path: "/sdwan-lite/edit",
			name: "Edit SDWAN Lite",
			component: AddSDWANWizardLite,
			props: true,
		},
		{
			path: "/sdwan-lite/add-provisioned/:device_id",
			name: "Start SDWAN Lite",
			component: AddSDWANWizardProvisionedLite,
			props: true,
		},
		{
			path: "/sdwan-lite/edit-provisioned/:device_id",
			name: "Edit Provisioned SDWAN Lite",
			component: AddSDWANWizardProvisionedLite,
			props: true,
		},
		{
			path: "/sdwan-lite/detailed-status/:sdwan_contract_id",
			name: "SDWAN Lite Details",
			component: SdwanDetailsContentLite,
			props: true,
		},
		// {
		// 	path: "/sdwan/shield/:customerID",
		// 	name: "Uania Web Panel",
		// 	component: WrapperIframe,
		// 	props: true,
		// },
		{
			path: "/github",
			beforeEnter() {
				location.href = process.env.VUE_APP_BACKOFFICE_URL;
			},
		},
	],
};
let contentFiltering = {
	path: "/content-filtering",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List Content Filtering",
			component: ListContentFiltering,
		},
		{
			path: "/content-filtering/add",
			name: "Add Content Filtering",
			component: NewContentFiltering,
			props: true,
		},
		{
			path: "/content-filtering/:sso",
			name: "FlashStart Web Panel",
			component: WrapperIframeFS,
			props: true,
		},
	],
};

let cloudtikElasticIps = {
	path: "/elastic-ips",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List Elastic IPs",
			component: ListElasticIps,
		},
		{
			path: "/add",
			name: "Add Elastic IP",
			component: NewElasticIp,
		},
	],
};

let cloudtikMonitoring = {
	path: "/monitoring",
	component: DashboardLayout,
	children: [
		{
			path: "",
			component: MonitoringFrame,
		},
	],
};

let cloudtikNotifications = {
	path: "/notifications",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List notifications",
			component: ListNotifications,
		},
	],
};

let cloudtikDeviceGroups = {
	path: "/device-groups",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List device groups",
			component: ListDeviceGroups,
		},
		{
			path: "/add",
			name: "Add device group",
			component: NewDeviceGroup,
		},
		{
			path: "/edit",
			name: "Edit device group",
			component: EditDeviceGroupForm,
			props: true,
		},
	],
};

let cloudtikDeviceBackups = {
	path: "/backups",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List devices with backup",
			component: ListDevicesWithBackup,
		},
		{
			path: "/devices/:device_id",
			name: "Get device backups",
			component: ListDeviceBackups,
			props: true,
		},
	],
};

let cloudtikTroubleshooting = {
	path: "/troubleshooting",
	component: DashboardLayout,
	children: [
		{
			path: "/devices/:device_id",
			name: "Device troubleshooting",
			component: Troubleshooting,
			props: true,
		},
	],
};

let cloudtikProfile = {
	path: "/profile",
	component: DashboardLayout,
	children: [
		{
			path: "",
			component: EditProfileForm,
		},
	],
};

let cloudtikLogout = {
	path: "/logout",
	component: DashboardLayout,
	children: [
		{
			path: "",
			component: Logout,
		},
	],
};

let cloudtikTenant = {
	path: "/tenants",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List Tenants",
			component: ListTenant,
		},
		{
			path: "/add-tenant",
			name: "Add Tenant",
			component: NewTenantForm,
		},
		{
			path: "/edit-tenant/:tenant_id",
			name: "Edit Tenant",
			component: NewTenantForm,
			props: true,
		},
	],
	beforeEnter(_, _2, next) {
		let userData = TokenService.getUser();
		// if userData.Role is admin or superadmin, allow access
		if (userData.role.toLowerCase() === "admin" || userData.role.toLowerCase() === "super-admin") {
			next();
		}
		// if (userData.isAdmin) next();
		else next("/dashboard");
	},
};

let cloudtikCustomer = {
	path: "/customers",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List Customers",
			component: ListCustomer,
		},
		{
			path: "/add-customer",
			name: "Add Customer",
			component: NewCustomerForm,
		},
		{
			path: "/edit-customer/:customer_id",
			name: "Edit Customer",
			component: NewCustomerForm,
			props: true,
		},
	],
	beforeEnter(_, _2, next) {
		let userData = TokenService.getUser();
		// if userData.Role is admin or superadmin, allow access
		if (userData.role.toLowerCase() === "admin" || userData.role.toLowerCase() === "super-admin") {
			next();
		}
		// if (userData.isAdmin) next();
		else next("/dashboard");
	},
};

let cloudtikUser = {
	path: "/users",
	component: DashboardLayout,
	children: [
		{
			path: "",
			name: "List Users",
			component: ListUser,
		},
		{
			path: "/add-user",
			name: "Add User",
			component: NewUserForm,
		},
		{
			path: "/edit-user/:user_id",
			name: "Edit User",
			component: NewUserForm,
			props: true,
		},
	],
	beforeEnter(_, _2, next) {
		let userData = TokenService.getUser();
		// if userData.Role is admin or superadmin, allow access
		if (userData.role.toLowerCase() === "admin" || userData.role.toLowerCase() === "super-admin") {
			next();
		}
		// if (userData.isAdmin) next();
		else next("/dashboard");
	},
};

let cloudtikStats = {
	path: "/stats",
	component: DashboardLayout,
	children: [
		{
			path: "/stats",
			name: "Stats",
			component: Stats,
		},
	],
};

let cloutdikLicenses = {
	path: "/licenses",
	component: DashboardLayout,
	children: [
		{
			path: "/licenses",
			name: "Licenses",
			component: Licenses,
		},
		{
			path: "/edit/:license_id",
			name: "Edit License",
			component: LicenseForm,
			props: true,
		},
		{
			path: "/add",
			name: "Add License",
			component: LicenseForm,
			props: true,
		},
	],
};

const routes = [
	{
		path: "/",
		redirect: "/dashboard",
		name: "Home",
	},
	// componentsMenu,
	// formsMenu,
	// tablesMenu,
	// mapsMenu,
	// pagesMenu,
	authPages,
	cloudtikProvisioners,
	cloudtikDevices,
	cloudtikTemplates,
	cloudtikSDWAN,
	cloudtikSDWANLite,
	cloudtikMonitoring,
	cloudtikNotifications,
	cloudtikDeviceGroups,
	cloudtikDeviceBackups,
	cloudtikTroubleshooting,
	cloudtikProfile,
	cloudtikLogout,
	cloudtikCustomer,
	cloudtikUser,
	cloudtikTenant,
	contentFiltering,
	cloudtikElasticIps,
	cloudtikStats,
	cloutdikLicenses,
	{
		path: "/",
		component: DashboardLayout,
		children: [
			{
				path: "dashboard",
				name: "Dashboard",
				components: { default: Dashboard },
			},
			// {
			//   path: "calendar",
			//   name: "Calendar",
			//   components: { default: Calendar },
			// },
			// {
			//   path: "charts",
			//   name: "Charts",
			//   components: { default: Charts },
			// },
			// {
			//   path: "widgets",
			//   name: "Widgets",
			//   components: { default: Widgets },
			// },
		],
	},
];

export default routes;
