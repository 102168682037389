<template>
	<ValidationObserver ref="form">
		<form @submit.prevent="validate">
			<ValidationProvider name="gateway" rules="required" v-slot="{ passed, failed }">
				<md-field
					:class="[
						{ 'md-error': failed },
						{ 'md-valid': passed },
						{ 'md-form-group': true },
						provisionedSDWAN ? { 'md-no-border': true } : {},
					]">
					<md-icon>link</md-icon>
					<label v-if="!provisionedSDWAN">Gateway</label>
					<md-input v-model="gw.gateway" type="text" v-if="!provisionedSDWAN"> </md-input>
					<md-autocomplete
						v-model="gw.gateway"
						:md-options="gatewayList"
						class="gatewaysMenu"
						v-if="provisionedSDWAN">
						<label>Gateway</label>
					</md-autocomplete>

					<slide-y-down-transition>
						<md-icon class="error" v-show="failed">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="passed">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</ValidationProvider>
			<!-- <md-checkbox v-model="gw.is_voice">Is this a gateways used for voice?</md-checkbox> -->
			<!-- Local preference selection -->
			<ValidationProvider name="local_preference" rules="required|integer" v-slot="{ passed, failed }">
				<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
					<md-icon>done</md-icon>
					<label>Position (local_preference)</label>
					<md-tooltip md-direction="top"><p v-html="$t('sdwan.localPreferenceTooltip')"></p></md-tooltip>
					<!-- SDWAN full -->
					<md-select v-model="gw.options.local_preference" name="local_preference" v-if="sdwanType !== '2'">
						<md-option value="110">110</md-option>
						<md-option value="109">109</md-option>
						<md-option value="108">108</md-option>
						<md-option value="107">107</md-option>
					</md-select>
					<!-- SDWAN LITE -->
					<!-- <md-select v-model="gw.options.local_preference" name="local_preference" v-if="sdwanType === '2'">
						<md-option
							v-if="
								!gateways.some(
									(g) => g.options.local_preference === '110' || g.options.local_preference === 110
								)
							"
							value="110">
							Primary (110)
						</md-option>
						<md-option
							v-if="
								!gateways.some(
									(g) => g.options.local_preference === '109' || g.options.local_preference === 109
								)
							"
							value="109">
							Backup (109)
						</md-option> 
					</md-select> -->
					<md-select v-model="gw.options.local_preference" name="local_preference" v-if="sdwanType === '2'">
						<md-option v-for="option in availablePreferences" :key="option.value" :value="option.value">
							{{ option.label }}
						</md-option>
					</md-select>

					<slide-y-down-transition>
						<md-icon class="error" v-show="failed">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="passed">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</ValidationProvider>
			<collapse v-if="sdwanType !== '2'" :collapse="[$t('sdwan.advancedSettings')]" icon="keyboard_arrow_down">
				<template slot="md-collapse-pane-1">
					<!-- Max Latency -->
					<ValidationProvider name="max_latency_up" rules="required|integer" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>done</md-icon>
							<label>Max latency (ms - millisec.)</label>
							<md-tooltip md-direction="top"><p v-html="$t('sdwan.maxLatencyUpTooltip')"></p></md-tooltip>
							<md-input v-model.number="gw.options.max_latency_up" type="text"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>
					<ValidationProvider name="max_latency_count" rules="required|integer" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>done</md-icon>
							<label>Max latency COUNT</label>
							<md-tooltip md-direction="top"><p v-html="$t('sdwan.maxLatencyCount')"></p></md-tooltip>
							<md-input v-model.number="gw.options.max_latency_count" type="text"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>

					<ValidationProvider name="max_packet_loss_up" rules="required|integer" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>done</md-icon>
							<label>Max packet loss (%)</label>
							<md-tooltip md-direction="top"
								><p v-html="$t('sdwan.maxPacketLossUpTooltip')"></p
							></md-tooltip>
							<md-input v-model.number="gw.options.max_packet_loss_up" type="text"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>

					<ValidationProvider
						name="max_packet_loss_count"
						rules="required|integer"
						v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>done</md-icon>
							<label>Max packet loss COUNT</label>
							<md-tooltip md-direction="top"><p v-html="$t('sdwan.maxPacketLossCount')"></p></md-tooltip>
							<md-input v-model.number="gw.options.max_packet_loss_count" type="text"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>

					<ValidationProvider name="bad_counter" rules="required|integer" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>done</md-icon>
							<label>Observation time when this line is not good</label>
							<md-tooltip md-direction="top"><p v-html="$t('sdwan.badCounter')"></p></md-tooltip>
							<md-input v-model.number="gw.options.bad_counter" type="text"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>
					<ValidationProvider name="check_frequency" rules="required|integer" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>done</md-icon>
							<label>Check frequency (s - second)</label>
							<md-tooltip md-direction="top"><p v-html="$t('sdwan.checkFrequency')"></p></md-tooltip>
							<md-input v-model.number="gw.options.check_frequency" type="text"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>
				</template>
			</collapse>
			<md-button class="md-raised md-previous" @click.native="cancelGateway()">Cancel</md-button>&nbsp;
			<md-button class="md-raised md-success" @click.native="saveGateway()">Save</md-button>
		</form>
	</ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { integer } from "vee-validate/dist/rules";
import { Collapse } from "@/components";

extend("required", required);
extend("integer", integer);

export default {
	name: "GatewayForm",
	props: ["gatewayToEdit", "gatewayList", "provisionedSDWAN", "sdwanType", "gateways"],

	computed: {
		availablePreferences() {
			if (this.gateways.length === 0) {
				return [{ value: "110", label: "Primary (110)" }];
			} else if (
				this.gateways[0]?.options.local_preference === "110" ||
				this.gateways[0]?.options.local_preference === 110
			) {
				return [{ value: "109", label: "Backup (109)" }];
			} else {
				return [{ value: "110", label: "Primary (110)" }];
			}
		},
	},

	mounted() {
		if (this.gatewayToEdit) {
			this.initialize();
		} else if (this.sdwanType === "2") {
			this.gw.options = {
				...this.gw.options,
				max_latency_up: 1000000,
				max_latency_down: 1000000,
				max_packet_loss_up: 100,
				max_packet_loss_down: 100,
				max_packet_loss_count: 10,
				check_frequency: 21600,
				local_preference: this.gateways.length === 0 ? "110" : "109",
			};
		}
	},
	components: {
		SlideYDownTransition,
		Collapse,
	},
	data() {
		return {
			gw: {
				gateway: "",
				is_voice: false,
				options: {
					max_latency_up: this.sdwanType === "2" ? 1000000 : 100,
					max_latency_down: 100000,
					max_latency_count: 5,
					max_packet_loss_up: this.sdwanType === "2" ? 100 : 20,
					max_packet_loss_down: 5,
					max_packet_loss_count: 2,
					max_jitter_up: 200000,
					max_jitter_down: 10000,
					max_jitter_count: 10000,
					bad_counter: 60,
					check_frequency: this.sdwanType === "2" ? 21600 : 1,
					local_preference: 110,
				},
			},
		};
	},
	methods: {
		initialize() {
			if (this.gatewayToEdit) {
				this.gw = this.gatewayToEdit;
			}
		},
		validate() {
			return this.$refs.form.validate().then((res) => {
				this.$emit("on-validated", res);
				return res;
			});
		},
		saveGateway() {
			// transforming from milliseconds to microseconds
			this.gw.options.max_latency_up = this.gw.options.max_latency_up * 1000;
			this.$refs.form.validate().then((res) => {
				if (res) {
					this.$emit("gatewaySaved", this.gw);
				}
			});
		},
		cancelGateway() {
			this.$emit("hideGatewayEdit");
		},
	},
	watch: {
		gatewayToEdit: {
			handler() {
				this.initialize();
			},
			immediate: true,
		},
	},
};
</script>
<style>
.gatewaysMenu {
	margin-top: -2px !important;
	margin-left: 10px;
	padding-bottom: 10px;
}
.md-no-border::after {
	height: 0px !important;
	margin-left: unset;
}
</style>
